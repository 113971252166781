import React, {Component} from 'react';
import './App.scss';
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Map from "./components/Map/Map";
import {getFileNames, getFolderNames, getFile, url} from "./sdkConfig";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientID: null,
            folders: [],
            selectedFolder: null,
            files: [],
            selectedFile: null,
            couriers: [],
            couriersCheckboxes: [],
            courierIndex: null,
            areAllChecked: true,
            scores: {
                hardScore: 0,
                mediumScore: 0,
                softScore: 0,
            },
        };

        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChangeAllCheckboxes = this.handleChangeAllCheckboxes.bind(this);
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const clientID = query.get('clientID') || 'stage';
        const folderName = query.get('folder') || null;
        const fileName = query.get('file') || null;

        getFolderNames(clientID).then(res => {
            const folders = res?.CommonPrefixes?.map(commonPrefix => {
                const prefixes = commonPrefix?.Prefix?.split('/');
                return prefixes[prefixes.length - 2];
            });
            folders?.reverse();
            this.setState({
                folders: folders || [],
                clientID,
                selectedFolder: folderName,
                selectedFile: fileName,
            }, () => {
                if (folderName)
                    this.handleChangeDropdown(folderName, 'folder');
                if (fileName)
                    this.handleChangeDropdown(fileName, 'file');
            });
        });
    }

    handleChangeDropdown(reason, type) {
        if (type === 'folder') {
            if (reason)
                getFileNames(this.state.clientID, reason).then(res => {
                    const files = res?.Contents?.map(content => {
                        const path = content?.Key?.split('/');
                        return path[path?.length - 1];
                    });
                    this.setState({
                        selectedFolder: reason,
                        files: files || [],
                    });
                });
            else
                this.setState({selectedFolder: null});
        }

        if (type === 'file') {
            if (reason)
                getFile(this.state.clientID, this.state.selectedFolder, reason)
                    .then(res => res.json())
                    .then(res => {
                        const couriersCheckboxes = res?.couriers.map(() => true);
                        const scores = {
                            hardScore: res?.hard_score || 0,
                            mediumScore: res?.medium_score || 0,
                            softScore: res?.soft_score || 0,
                        }
                        this.setState({
                            selectedFile: reason,
                            couriers: res?.couriers || [],
                            couriersCheckboxes: couriersCheckboxes || [],
                            areAllChecked: true,
                            fileUrl: `${url}${this.state.clientID}/${this.state.selectedFolder}/jsons/${reason}`,
                            scores,
                        });
                });
            else
                this.setState({selectedFile: null});
        }
    }

    handleChangeCheckbox(index) {
        const couriersCheckboxes = [...this.state.couriersCheckboxes];
        couriersCheckboxes[index] = !couriersCheckboxes[index];
        this.setState({
            couriersCheckboxes,
            courierIndex: index,
        });
    }

    handleChangeAllCheckboxes() {
        const areAllChecked = !this.state.areAllChecked;
        const couriersCheckboxes = this.state.couriersCheckboxes.map(() => areAllChecked);
        this.setState({
            couriersCheckboxes,
            areAllChecked,
        });
    }

    render() {
        const {clientID, folders, selectedFolder, files, selectedFile, couriers, couriersCheckboxes, courierIndex, areAllChecked, scores} = this.state;

        return (
            <div className="App">
                <Header
                    clientID={clientID}
                    folders={folders}
                    selectedFolder={selectedFolder}
                    files={files}
                    selectedFile={selectedFile}
                    scores={scores}
                    handleChangeDropdown={this.handleChangeDropdown}
                />
                <Sidebar
                    couriers={couriers}
                    couriersCheckboxes={couriersCheckboxes}
                    areAllChecked={areAllChecked}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                    handleChangeAllCheckboxes={this.handleChangeAllCheckboxes}
                />
                <Map
                    couriers={couriers}
                    couriersCheckboxes={couriersCheckboxes}
                    courierIndex={courierIndex}
                    areAllChecked={areAllChecked}
                />
            </div>
        );
    }
}

export default App;
