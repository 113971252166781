import React from 'react';
import './Map.scss';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {colors} from "../../courierColors";

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmVlYW1pcmNlYSIsImEiOiJja3o2djg2bXEwZmp1MnZzOGlwMTF3ZWd0In0.ITCenL6a4VOAmnPRV_5fbw';

const popupOffsetX = -15;
const popupOffsetY = -54;

export default class Map extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lng: 26.1025,
            lat: 44.4360,
            zoom: 11,
            map: null,
            markers: [],
        };

        this.mapContainer = React.createRef();
    }

    componentDidMount() {
        const {lng, lat, zoom} = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false,
        });
        this.setState({
            map,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.map && nextProps.couriers !== this.props.couriers) {
            this.addAllMarkers(nextProps.couriers);
            return;
        }

        if (this.state.map && nextProps.areAllChecked !== this.props.areAllChecked) {
            if (nextProps.areAllChecked)
                this.addAllMarkers(nextProps.couriers);
            else
                this.removeAllMarkers();
            return;
        }

        if (this.state.map && nextProps.couriersCheckboxes !== this.props.couriersCheckboxes) {
            if (nextProps.couriersCheckboxes[nextProps.courierIndex]) {
                const markers = [...this.state.markers];
                markers[nextProps.courierIndex] = this.addCourierMarkers(nextProps.couriers[nextProps.courierIndex], nextProps.courierIndex);
                this.setState({markers});
            } else
                this.removeCourierMarkers(nextProps.courierIndex);
        }

    }

    addAllMarkers(couriers) {
        this.removeAllMarkers();
        const markers = [];
        couriers?.forEach((courier, index) => {
            markers.push(this.addCourierMarkers(courier, index, markers));
        });
        this.setState({markers});
    }

    addCourierMarkers(courier, index) {
        const color = colors[index] || 'black';
        const markers = [];
        courier?.route?.locations
            ?.filter((location, index) => location.id !== 'FACTORY' && index !== 0)
            .forEach((location, index) => {
                const lng = location?.coordinates?.longitude;
                const lat = location?.coordinates?.latitude;
                const eta = location?.eta ? new Date(location?.eta).toLocaleString() : '';
                const startTime = location?.time_window?.start_time ? new Date(location.time_window.start_time).toLocaleString() : '';
                const endTime = location?.time_window?.end_time ? new Date(location?.time_window?.end_time).toLocaleString() : '';

                let el = document.createElement('div');
                el.className = 'marker';
                el.innerHTML = `<span style="background-color: ${color}"><b>${index + 1}</b></span>`;

                const marker = new mapboxgl.Marker(el)
                    .setLngLat([lng, lat])
                    .setPopup(
                        new mapboxgl.Popup({
                            offset: [popupOffsetX, popupOffsetY]
                        }).setHTML(`
                                <div class="popup-content">
                                    <span>ETA</span>
                                    <div>${eta}</div>
                                    <span>Time Window</span>
                                    <div>${startTime}</div>
                                    <div>${endTime}</div>
                                </div>
                            `)
                    )
                    .addTo(this.state.map);
                markers.push(marker);
            });

        return markers;
    }

    removeAllMarkers() {
        const markers = this.state.markers;
        markers.map(courierMarkers => {
            courierMarkers.map(marker => marker.remove());
        });
        this.setState({markers});
    }

    removeCourierMarkers(index) {
        const markers = this.state.markers;
        markers[index].map(marker => marker.remove());
        this.setState({markers});
    }

    render() {
        return (
            <div className='map'>
                <div className='map-container' ref={this.mapContainer}/>
            </div>
        )
    }
}