import './Header.scss';
import React from 'react';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {url} from '../../sdkConfig';

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiFormControl-root': {
        border: '1px solid #69626D',
        borderRadius: '10px',
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '& .MuiAutocomplete-input': {
        opacity: '1',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: '6px',
    }
});

const Header = ({clientID, folders, selectedFolder, files, selectedFile, scores, handleChangeDropdown}) => {
    const getValueForDropdown = (options, selectedOption) => {
        if (!options || !options.length) return '';

        let response = [];
        options.forEach(el => {
            if (el === selectedOption)
                response.push(el);
        });

        return response[0] ? response[0] : null;
    };

    return (
        <div className='header-container'>
            <div className='dropdown-container'>
                <StyledAutocomplete
                    options={folders}
                    value={getValueForDropdown(folders, selectedFolder)}
                    renderInput={(params) =>
                        <TextField {...params}
                                   placeholder='Select folder'
                                   variant="outlined"
                        />}
                    onChange={(event, reason) => handleChangeDropdown(reason, 'folder')}
                >
                </StyledAutocomplete>
            </div>
            <div className='dropdown-container'>
                <StyledAutocomplete
                    options={files}
                    value={getValueForDropdown(files, selectedFile)}
                    renderInput={(params) =>
                        <TextField {...params}
                                   placeholder='Select file'
                                   variant="outlined"
                        />}
                    onChange={(event, reason) => handleChangeDropdown(reason, 'file')}
                >
                </StyledAutocomplete>
            </div>
            <div className='scores-container'>
                <div><span>Hard Score:</span> {scores?.hardScore}</div>
                <div><span>Medium Score:</span> {scores?.mediumScore}</div>
                <div><span>Soft Score:</span> {scores?.softScore}</div>
            </div>
            <div className='buttons-container'>
                <a
                    href={clientID && selectedFolder && `${url}${clientID}/${selectedFolder}/index.html`}
                    target="_blank"
                    rel="noreferrer"
                    className={`button ${!selectedFolder ? 'disabled' : ''}`}
                >
                    View report
                </a>
                <a
                    href={clientID && selectedFolder && selectedFile && `${url}${clientID}/${selectedFolder}/jsons/${selectedFile}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`button ${!(selectedFolder && selectedFile) ? 'disabled' : ''}`}
                >
                    View json
                </a>
            </div>
        </div>
    );
};

export default Header;