import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3";

const bucketName = 'rapidonkey-benchmark';
const region = 'eu-central-1';
const mainFolder = 'improved_results/';
export const url = `https://s3.${region}.amazonaws.com/${bucketName}/${mainFolder}`;

const s3 = new S3Client({
    region: region,
    credentials: {
        accessKeyId: 'AKIAXXCMAYHDCO66TTUB',
        secretAccessKey: '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',
    }
});

export function getFolderNames(clientID) {
    return s3.send(
        new ListObjectsV2Command({
            Prefix: `${mainFolder}${clientID}/`,
            Delimiter: '/',
            Bucket: bucketName,
        })
    );
}

export function getFileNames(clientID, folderName) {
    const prefix =`${mainFolder}${clientID}/${folderName}/jsons/`;
    return s3.send(
        new ListObjectsV2Command({
            Prefix: prefix,
            Delimiter: '/',
            Bucket: bucketName,
        })
    );
}

export function getFile(clientID, folderName, fileName) {
    return fetch(`${url}${clientID}/${folderName}/jsons/${fileName}`);
}

