import React from 'react';
import './Sidebar.scss';
import {colors} from "../../courierColors";

const Sidebar = ({couriers, couriersCheckboxes, handleChangeCheckbox, handleChangeAllCheckboxes, areAllChecked}) => {
    return (
        <div className='sidebar-container'>
            {
                couriers?.length !== 0 && <div className='button' onClick={() => handleChangeAllCheckboxes()}>{areAllChecked ? 'Hide all' : 'Show all'}</div>
            }
            {
                couriers.map((courier, index) => {
                    const pins = courier?.route?.locations?.filter((location, index) => location.id !== 'FACTORY' && index !== 0).length || 0;
                    const color = colors[index] || 'black';
                    return (
                        <div key={index} className='courier-container' style={{backgroundColor: color}}>
                            <input type='checkbox' checked={couriersCheckboxes[index]} onChange={() => handleChangeCheckbox(index)}/>
                            <div className='courier-name'>{courier.id}</div>
                            <div className='courier-pins'>{pins}</div>
                        </div>
                    );
                })
            }
        </div>
    )
};

export default Sidebar;
