export const colors = [
    '#5C7BAD',
    '#DDC4FE',
    '#FF99AA',
    '#FF695C',
    '#F7AE79',
    '#FAF191',
    '#8ADE8F',
    '#17521B',
    '#166088',
    '#0A18B7',
    '#3C0E85',
    '#660900',
    '#C21E52',
    '#C54630',
    '#F17115',
    '#FFEA00',
    '#A8774D',
    '#4F3824',
    '#666370',
    '#1C1F33',
    '#161216',
    '#5DA271',
    '#3F7CAC',
    '#4E33FF',
    '#5B2A86',
    '#8C175B',
];